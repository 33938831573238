//
export const ENVIRONMENT_GLOBAL = {
  appStoreLink: 'https://itunes.apple.com/us/app/one-medical-mobile/id393507802?mt=8',
  appSync: {
    apiUrl: 'https://eyyrallbu5ende7evmle6nurnm.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    enabled: true,
  },
  contactSupportLink: 'https://www.onemedical.com/contact-us',
  devRoutes: false,
  enableProdMode: false,
  googlePlayLink: 'https://play.google.com/store/apps/details?id=com.onemedical.android&hl=en',
  googlePlacesKey: 'AIzaSyC0JyraubIloLkfFIj8kMDNLfQJ83JHe2U',
  googleTagManagerId: 'GTM-WSN7TRS',
  googleConversionId: 1039580055,
  hintPortalUrl: 'https://onemedical.hint.com/login/request',
  launchDarklyClientId: '6018851e17303e09d8153a73',
  marketingSite: 'https://mktg-stage.onemedical.com',
  mixpanelToken: '44008e1ef10bb5ecc7b4f967257c824d',
  mixpanelDebug: false,
  mixpanelCrossDomain: false,
  oneMedicalPhone: '415-291-0480',
  opsUrl: 'https://staging.patientops.com',
  pusherKey: '5422148b626236f6bff3',
  recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  sentry: {
    enabled: false,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: '',
  },
  stripePublishableKey: 'pk_test_n8l9UNf6ai3uDaNVAiuWp8Tu',
  topLevelDomain: '',
  typeaheadDataLoggingUrl: 'https://any-ac-ml-qualification.app.1life.com/data-logging/member',
  typeaheadSearchUrl: 'https://any-ac-ml-qualification.app.1life.com/autocomplete/member',
  typeaheadTestingMode: true,
};
