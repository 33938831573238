import includes from 'lodash-es/includes';

export enum Features {
  BHX_SHIFT_HOMESCREEN_PROMO = 'shift-homescreen-promo',
  BHX_RECOMMENDED_SERVICES_MENTAL_HEALTH_CARD = 'recommended-services-mental-health-card',
  CORONAVIRUS_BANNER_ENABLED = 'coronavirus_banner_enabled',
  CORONAVIRUS_CARD_ENABLED = 'coronavirus_card_enabled',
  PEDIATRIC_CONSUMER_REGISTRATION_PROMOTION = 'pediatric_consumer_registration_promotion',
  SHOW_VIRTUAL_DESCRIPTION_REGISTRATION = 'show_virtual_description_registration',
  BHX_PROVIDER_BIOS_IN_BOOKING_EXPERIMENT = 'provider-bios-in-booking-experiment',
}
export class ActiveFeatureFlagCollection {
  activeFlags: string[];

  constructor(flags: string[]) {
    this.activeFlags = flags;
  }

  static fromApiV2(res: string[]): ActiveFeatureFlagCollection {
    return new ActiveFeatureFlagCollection(res);
  }

  isFeatureActive(feature_name: string): boolean {
    return includes(this.activeFlags, feature_name);
  }
}

export enum BiosInBookingExperiment {
  Off = 'OFF',
  Control = 'ON CONTROL',
  LinkOnly = 'ON LINK ONLY',
  WithSummary = 'ON WITH SUMMARY',
}
